<template>
  <div>
    <img
        :src="require(isDark ? '@/assets/images/logo/promostore-white.svg' : '@/assets/images/logo/promostore.svg') "
        width="200"
        alt="PromoStore"
    >
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')
    return { skin, isDark }
  },
}
</script>
